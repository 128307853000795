import React, {useEffect, useState} from "react"
import "./BillUploadForm.scss"
import {
  PresentationOptionsNew,
  commonVariables,
  componentRole,
  S3FILEPATH,
  dateFormats
} from "../../../../constants/Common/commonConstants"
import {useDispatch, useSelector} from "react-redux"
import {showBar} from "../../../../redux/reducer/sideBarStatus"
import Button from "../../../../component/common/Button/button"
import FormLabel from "../../../../component/common/formLabel/formLabel"
import {ReactComponent as FileIcon} from "../../../../Images/file.svg"
import apiCall from "../../../../utils/apiFunction/apiCall"
import uploadPresigned from "../../../../utils/presignedUpload"
import {toast} from "react-toastify"
import {
  admin,
  apiConstants,
  attributePaymentConstants,
  errMessage,
  tableNamePaymentConstants,
  toastIds
} from "../../../../constants/Common/apiConstants"
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader"
import axios from "axios"
import {Button as ButtonReact, Col, Row} from "react-bootstrap"
import Dropzone from "react-dropzone"
import moment from "moment"
import ReactDatePicker from "react-datepicker"

const BillUploadForm = (props) => {
  const dispatch = useDispatch()
  const [fileData, setFileData] = useState([])
  const [companyCode, setCompanyCode] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [apiLoading, setApiLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    company_code: {
      value: "",
      error: "",
      required: true,
    },
    presentation_type: {
      value: "",
      error: "",
      required: true,
    },
    settlement_date: {
      value: "",
      error: "",
      required:  false,
    }
  })
  const loginState = useSelector((state) => state.login)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [buttonClick, setButtonClick] = useState(false)

  useEffect(() => {
    getCompanyCode()
  }, [formValues])

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      settlement_date: {
        ...prevFormValues.settlement_date,
        required: prevFormValues.presentation_type.value === "Representation_UPI",
      },
    }));
  }, [formValues.presentation_type.value]);

  const getCompanyCode = () => {
    let paramObj = {
      offset: 0,
      limit: 10,
      tablename: tableNamePaymentConstants?.Company,
      whereQuery: {
        isActive: 1,
      },
    }
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response
        result = result.map((item) => {
          let newItem = {
            value: item?.company_code,
            label: item?.company_name,
          }
          return newItem
        })
        setCompanyCode(result)
      })
      .catch((er) => {
        console.log("errr =>>>>", er)
      })
  }

  const onFileUpload = (file) => {
    if (file.length > 0) {
      if(file[0].name.includes(' ')){
        toast.error(
          "Please remove spaces from filename",
          { toastId: toastIds?.FileUploaderr }
        );
      }else{
        setFileData(file);
      }
    }
  }
  const uploadLoder = (data) => {
    setUploadPercent(data)
  }
  const getPresigned = (value,batch_id) => {
    setLoading(true)
    const s3key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS}/${S3FILEPATH?.DT?.RPT_BILL_UPLOAD}/${batch_id}/${value[0].name}`
    apiCall
      .get(
        `${apiConstants.apicommonUtilityPresignedS3Upload}?key=${s3key}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data
          if (result) {
            setLoading(true)
            const cancelToken = axios.CancelToken
            const source = cancelToken.source()
            setCancelTokenSource(source)
            uploadPresigned(value[0], result, uploadLoder, source)
              .then((res) => {
                if (res.status == 200) {
                  setFileData(value);
                  onSubmitApi(batch_id,s3key);
                }
              })
              .catch((e) => {
                toast.error(errMessage?.fileUploadFailed, {toastId: toastIds?.FileUploaderr})
              })
              .finally(() => {
                setLoading(false)
              })
          }
        }
      })
      .catch((er) => {
        toast.error(errMessage?.fileUploadFailed, {toastId: toastIds?.FileUploaderr})
      })
      .finally(() => {
        setLoading(false)
      })
  }

   const onUpdate = (data) => {
    if (fileData.length) {
      let apiBody = {
        batch_id: data.id || "",
      };
      setApiLoading(true);
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBody,
          tablename: "debit_tran_reg_file_upload_status",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              getPresigned(fileData, data.id);
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            });
          }
        })
        .catch((er) => {
          console.log("err", er);
          setApiLoading(false);
          let resp = er?.message || "";
          toast.error(resp, { toastId: toastIds?.FileUploaderr });
        })
        .finally(() => {
        });
    } else {
      toast.error(errMessage?.uploadFile, { toastId: toastIds?.FileUploaderr });
    }
  };

  const onSubmit = () => {
    let userData = loginState?.decodedData
    if (fileData.length) {
        let apiBody = { 
        company_code:formValues?.company_code?.value,
        presentation_type:formValues?.presentation_type?.value.includes("Representation")?
                          "Representation" : formValues?.presentation_type?.value , 
        file_name: fileData[0].name,
        user_name: userData?.user_name,
        upload_date_time: moment(new Date()).format(dateFormats.dateFormatInMDYWithTimeAndAm), 
        upload_status: commonVariables?.PROCESSING,
        mandate_date : formValues?.settlement_date?.value || "",
        mandate_type:formValues?.presentation_type?.value=="Representation_UPI" ? "UPI Mandate" : "",
        user_role: admin ,
        module_name: commonVariables?.BILL_UPLOAD
        };
         setApiLoading(true);
        apiCall
          .post(apiConstants?.createUser, {
            body: apiBody,
            tablename: "debit_tran_reg_file_upload_status",
          })
          .then(async (res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.response?.data?.id) {
                onUpdate(res.data.response.data);
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  {
                    toastId: toastIds?.FileUploaderr,
                  }
                );
              }
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
              setButtonClick(false);
            }
          })
          .catch((er) => {
            console.log("err", er);
            setApiLoading(false);
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => {
          });
    } else {
        toast.error(errMessage?.uploadFile, {toastId: "uploaderror"})
        setButtonClick(false)
    }
  }

  const onSubmitApi = (batch_Id,s3key) => {
    // if (data.length) {
    let userData = loginState?.decodedData
    if (fileData.length) {
      setLoading(true)
      let apiBody = {
        file_name: fileData[0]?.name || "",
        company_code: formValues?.company_code?.value || "",
        presentation_type:formValues?.presentation_type?.value.includes("Representation")?
          "Representation" : formValues?.presentation_type?.value,
        mandate_date: formValues?.settlement_date?.value || "",
        mandate_type: formValues?.presentation_type?.value == "Representation_UPI" ? "UPI Mandate" : "",
        key: fileData[0]?.name ? s3key : "",
        userName: userData?.user_name || "",
        userCode: userData?.user_code || "",
        roleData: admin,
        batchId: batch_Id
      }
        if(formValues?.presentation_type?.value.includes("UPI_Without_PDN")){
          apiBody.withoutPDNExe=true
        }else{
          apiBody.withoutPDNExe=false
        }
      apiCall
        .post(apiConstants?.billUpload, apiBody)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            toast.success("File Uploaded Successfully", "fileUploadSuccess")
            dispatch(showBar(false))
            handleCancel()
          } else {
            setLoading(false)
            let resp = res?.data?.message || errMessage?.somethingWentWrong
            toast.error(resp, {toastId: toastIds?.rptbillUpload})
          }
        })
        .catch((er) => {
          let resp = er?.response?.data?.message || errMessage?.somethingWentWrong
          toast.error(resp, {toastId: toastIds?.rptbillUpload})
        })
        .finally(() => {
          setButtonClick(false)
          setLoading(false)
        })
    } else {
      toast.error(errMessage?.uploadFile, {toastId: "uploaderror"})
      setButtonClick(false)
    }
    // }
  }
  
  const onChange = (e) => {
    let tempData = {...formValues}
    tempData[e.target.name].value = e.target.value
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true)
    setFormValues({...tempData})
  }

  const submitForm = () => {
    if (!validate()) {
      setButtonClick(true)
      onSubmit()
    } else {
      toast.error("Fill all required fields", {toastId: "uploaderror"})
    }
  }
  const validate = () => {
    let error = false
    let tempForm = {...formValues}
    Object.entries(tempForm).forEach((value) => {
      if (value[1].required) {
        if (value[1].value) {
          tempForm[value[0]].error = false
        } else {
          tempForm[value[0]].error = true
          error = true
          setButtonClick(false)
        }
      }
      return value
    })
    setFormValues({...tempForm})
    return error
  }
  const handleCancel = () => {
    setButtonClick(false)
    props?.onCancel()
    setUploadPercent(0)
    setFormValues({
      company_code: {
        value: "",
        error: "",
        required: true,
      },
      presentation_type: {
        value: "",
        error: "",
        required: true,
      },
    })
    setFileData([])
    setCompanyCode([])
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload canceled by user.")
    }
  }

  return (
    <>
      {loading || apiLoading || (uploadPercent > 0 && uploadPercent < 100) ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end mr-3">
            <Button
              role={componentRole?.TRANSPARENT_CANCEL || ""}
              onClick={() => dispatch(showBar(false), handleCancel())}
            />
          </div>
          <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
            <FormLabel value={commonVariables?.BILL_UPLOAD || ""} />
          </div>
          <div className="m-4">
            <div className="dynamicFormWrapper">
              <Row className="g-3 gx-5 mb-5">
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Company Code <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="company_code"
                      onChange={(e) => onChange(e)}
                      value={formValues.company_code.value}
                      className="form-select selectInput">
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {companyCode &&
                        companyCode.map((value) => {
                          return <option value={value.value}>{value.label}</option>
                        })}
                    </select>
                    {formValues.company_code.error && formValues.company_code.required && (
                      <p className="textLabelRed m-0 mt-2">Company Code is required</p>
                    )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Presentation Type <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="presentation_type"
                      onChange={(e) => onChange(e)}
                      value={formValues.presentation_type.value}
                      className="form-select selectInput">
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {PresentationOptionsNew &&
                        PresentationOptionsNew.map((value) => {
                          return <option value={value.value}>{value.label}</option>
                        })}
                    </select>
                    {formValues.presentation_type.error &&
                      formValues.presentation_type.required && (
                        <p className="textLabelRed m-0 mt-2">Company Code is required</p>
                      )}
                  </div>
                </Col>
                {formValues.presentation_type.value==="Representation_UPI" &&
                 <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                    Settlement Date<span className="textLabelRed">*</span>
                    </p>
                    <ReactDatePicker
                      name="settlement_date"
                      className="customDatepicker"
                      selected={formValues.settlement_date.value ? moment(formValues.settlement_date.value, "DD/MM/YYYY").toDate() : null}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        console.log("dayattt----------------  ",date)
                        setFormValues((prev) => ({
                          ...prev,
                          settlement_date: {
                            ...prev.settlement_date,
                            value: date ? moment(date).format("DD/MM/YYYY") : "",
                          },
                        }));
                      }}
                      minDate={moment().add(1, 'days').toDate()}
                      />
                    {formValues.settlement_date.required==="Representation_UPI" && (
                        <p className="textLabelRed m-0 mt-2">Settlement Date is required</p>
                      )}
                  </div>
                </Col>}
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>Upload File</p>
                    <Dropzone
                      onDrop={onFileUpload}
                      multiple={false}
                      accept={{
                        "text/csv": [".csv"],
                      }}
                      disabled={uploadPercent > 0 && uploadPercent < 100}>
                      {({getRootProps, getInputProps}) => (
                        <div
                          {...getRootProps({
                            className:
                              "dropWrapper d-flex align-items-center justify-content-center",
                          })}>
                          <input {...getInputProps()} />
                          <div className="d-flex justify-content-center flex-wrap">
                            <div className="d-flex gap-2 uploadContent">
                              {fileData[0]?.name && <FileIcon />}
                              {uploadPercent > 0 && uploadPercent < 100
                                ? `${uploadPercent}% Uploaded`
                                : fileData[0]?.name || (
                                    <div className="uploadComponentLabel">Upload File</div>
                                  )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Col>
                <div className="d-flex gap-4">
                  <ButtonReact
                    className="customButtonGreenInverted"
                    onClick={() => dispatch(showBar(false), handleCancel())}>
                    Cancel
                  </ButtonReact>
                  <ButtonReact
                    className="customButtonGreen"
                    disabled={(uploadPercent > 0 && uploadPercent < 100) || buttonClick}
                    onClick={() => submitForm()}>
                    Submit
                  </ButtonReact>
                </div>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BillUploadForm
